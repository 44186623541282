import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./zyfera.png"
								alt="zyfera"
								className="work-image"
							/>
							<div className="work-title">Zyfera</div>
							<div className="work-subtitle">
								Junior Software Engineer
							</div>
							<div className="work-duration">Jun 2024 - Present</div>
						</div>

						<div className="work">
							<img
								src="./fileorbis.jpg"
								alt="fileorbis"
								className="work-image"
							/>
							<div className="work-title">FileOrbis</div>
							<div className="work-subtitle">
								Software Engineer Intern
							</div>
							<div className="work-duration">Jun 2023 - September 2023</div>
						</div>
						<div className="work">
							<img
								src="./kafein.jpg"
								alt="kafein"
								className="work-image"
							/>
							<div className="work-title">Kafein Technology Solutions</div>
							<div className="work-subtitle">
								Software Engineer Intern
							</div>
							<div className="work-duration">Jun 2022 - August 2022</div>
						</div>


					</div>
				}
			/>
		</div>
	);
};

export default Works;
