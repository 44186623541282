const meText = "I am a Junior software engineer specializing particularly in backend development. I work with languages like Java and Kotlin, using frameworks such as Spring Boot to develop complex backend projects.\n" +
    "\n" +
    "Moreover, I have experience in mobile application development, designing and building Android applications using the Kotlin language. My deep interest in microservices architecture and API development strengthens my ability to design scalable and reliable systems. I closely follow the innovations in the technology world and continuously strive to improve myself. My goal is to develop professional-level backend solutions, providing effective software solutions tailored to the needs of companies.";
const keywords = ["Nuri Can", "Nuri Can Ö", "Nuri Can ÖZTÜRK"];
const SEO = [
    {
        page: "home",
        description: meText,
        keywords: keywords
    },

    {
        page: "about",
		description: meText,
        keywords: keywords
    },

    {
        page: "articles",
        description:
            "I've written a few articles on various topics. Feel free to check them out and let me know what you think.",
        keywords: keywords
    },

    {
        page: "projects",
        description:
            "I've worked on a few projects. Feel free to check them out and let me know what you think.",
        keywords: keywords
    },

    {
        page: "contact",
        description:
            "If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
        keywords: keywords
    },
];

export default SEO;
