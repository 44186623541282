const meText = "I am a Junior software engineer specializing particularly in backend development. I work with languages like Java and Kotlin, using frameworks such as Spring Boot to develop complex backend projects.\n" +
    "\n" +
    "Moreover, I have experience in mobile application development, designing and building Android applications using the Kotlin language. My deep interest in microservices architecture and API development strengthens my ability to design scalable and reliable systems. I closely follow the innovations in the technology world and continuously strive to improve myself. My goal is to develop professional-level backend solutions, providing effective software solutions tailored to the needs of companies.";
const INFO = {
    main: {
        title: "Nuri Can ÖZTÜRK",
        name: "Nuri Can Öztürk",
        email: "canozturk309@gmail.com",
        logo: "../logo.png",
    },

    socials: {
        github: "https://github.com/nuricanozturk01",
        linkedin: "https://www.linkedin.com/in/nuricanozturk/"
    },

    homepage: {
        title: "Junior Software Engineer",
        description: meText,
    },

    about: {
        title: "I’m Nuri Can ÖZTÜRK. I live in Turkey, where I work as a Junior Software Engineer.",
        description: meText,
    },

    articles: {
        title: "Articles",
        description:
            "I've written a few articles on various topics. Feel free to check them out and let me know what you think.",
    },

    projects: [
        {
            title: "Call-of-Project Backend",
            description:
                "Call-Of-Project is an application where people can find project partners, collaborate on projects together, and manage project management to some extent",
            logo: "./cop.jpg",
            linkText: "View Project",
            link: "https://github.com/nuricanozturk01/CallOfProject-Backend",
        },

        {
            title: "Call-of-Project Interview App",
            description:
                "The Call-Of-Project Interview Part enables project owners to conduct coding and test interviews as part of their projects. This feature allows users to participate in interviews, showcasing their problem-solving skills and knowledge in various programming languages.",
            logo: "./int_cop.png",
            linkText: "View Project",
            link: "https://github.com/nuricanozturk01/Call-Of-Project-Interview-Part",
        },

        {
            title: "Call-of-Project Admin Panel",
            description:
                "This application, Call-Of-Project, defines three fundamental roles, listed from most authorized to least authorized: Root, Admin, and User.",
            logo: "./admin_cop.png",
            linkText: "View Project",
            link: "https://github.com/nuricanozturk01/CallOfProject-AdminPanel",
        },

        {
            title: "Call-of-Project Android App",
            description:
                "The Call-of-Project Android app is the mobile counterpart of the Call-of-Project project. This mobile application incorporates some of the backend and frontend features. It employs the MVVM (Model-View-ViewModel) pattern.",
            logo: "./cop.jpg",
            linkText: "View Project",
            link: "https://github.com/nuricanozturk01/Call-Of-Project-Android",
        },

        {
            title: "File-Box",
            description:
                "FileBox is an open-source application that helps you create your own personal file management system. It offers a range of features for file management, including file upload, download, renaming, moving, copying, and more. Additionally, it allows you to filter files by type, date created, and file size, as well as create folders for better organization.",
            logo: "./filebox.png",
            linkText: "View Project",
            link: "https://github.com/nuricanozturk01/File-Box",
        },
        {
            title: "Unit-Test-Framework",
            description:
                "This framework allows you to create and run unit tests in Java, similar to JUnit. Below, you will find basic information and examples on how to use the Unit-Test-Framework.",
            logo: "./utf.png",
            linkText: "View Project",
            link: "https://github.com/nuricanozturk01/Unit-Test-Framework",
        },
    ],
};

export default INFO;
