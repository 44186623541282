
function article_1() {
    return {
        date: "Nov 17, 2022",
        title: "Spring Framework/Boot Core Annotations",
        description:
            "About Spring Boot core annotations knowledge",
        keywords: [
            "Spring Boot",
            "Spring Framework",
            "Spring Framework Core Annotations",
            "Annotations",
            "Java",
        ],
        style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
        link: "https://medium.com/@nuricanozturk01/spring-framework-boot-core-annotations-69c919305b2b"
    };
}

function article_2() {
    return {
        date: "Aug 4, 2022",
        title: "Git and Github Usage",
        description:
            "About Git and Github usage and knowledge",
        style: ``,
        keywords: [
            "Git",
            "Github",
            "Version Control",
            "Software Development"
        ],
       link: "https://medium.com/@nuricanozturk01/git-kullan%C4%B1m%C4%B1-292491fe2911"
    };
}

const myArticles = [article_1, article_2];

export default myArticles;
